import React, { Component } from "react";
import { TimePicker } from '@material-ui/pickers/TimePicker';
import MuiPickersUtilsProvider from '@material-ui/pickers/MuiPickersUtilsProvider';
import { ExecuteLayoutEventMethods, GetControlPropertyFromStoreOrRefData } from "../../../General/commonFunctions";
import DateFnsUtils from "@date-io/date-fns";
import { getTZPrefix } from "../../../General/globals";

class FactsTimePicker extends Component {
    constructor(props) {
        super(props);
        let dateValueFromState = GetControlPropertyFromStoreOrRefData("[" + props.dset + "." + props.bind + "]");
        // let dateInMillis = 0;
        // if (dateValueFromState) {
        //     dateInMillis = Date.parse(dateValueFromState);
        //     if (["iPhone", "iPod", "iPad", "MacIntel", "Macintosh", "MacPPC", "Mac68K"].includes(navigator.platform))
        //         dateInMillis = dateInMillis + (new Date().getTimezoneOffset() * 60 * 1000)
        // }
        // dateValueFromState = dateValueFromState + "+04:00";
        this.state = {
            dateValue: dateValueFromState
            // dateInMillis: dateInMillis
        }
    }
    // offsetForIphone(millis) {
    //     if (["iPhone", "iPod", "iPad", "MacIntel", "Macintosh", "MacPPC", "Mac68K"].includes(navigator.platform))
    //         return millis - (new Date().getTimezoneOffset() * 60 * 1000)
    // }
    async handleOnChange(date) {
        this.setState({
            dateValue: date
        }, () => this.changeBinding());
        await ExecuteLayoutEventMethods(this.props.whenchange);
    }
    async changeBinding() {
        if (this.props.dset && this.props.bind) {
            await ExecuteLayoutEventMethods([
                {
                    exec: "setdatasetfield",
                    args: {
                        dset: this.props.dset,
                        fieldname: this.props.bind,
                        data: this.state.dateValue
                    }
                }
            ]);
        }
    }
    render() {
        let modifiedDate = this.state.dateValue;
        if (typeof modifiedDate === "string") //Fix for Iphone
            modifiedDate = modifiedDate + getTZPrefix();

        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <TimePicker  {...this.props} value={modifiedDate} onChange={(date) => this.handleOnChange(date)} />
            </MuiPickersUtilsProvider>
        );
    }

    adjustTimeForIphone() {

    }
}
export default FactsTimePicker;